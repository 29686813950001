// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    NEW_PASSWORD,
    NEW_PASSWORD_SUCCESS,
    NEW_PASSWORD_FAILED,
    PARTNER_VERIFY,
    PARTNER_VERIFY_SUCCESS,
    PARTNER_VERIFY_FAILED,
    PARTNER_TEMPLATE_LIST,
    PARTNER_TEMPLATE_LIST_SUCCESS,
    PARTNER_TEMPLATE_LIST_FAILED,
    PARTNER_PORTFOLIO_LIST,
    PARTNER_PORTFOLIO_LIST_SUCCESS,
    PARTNER_PORTFOLIO_LIST_FAILED,
    USER_INFO,
    USER_INFO_FAILED,
    USER_INFO_SUCCESS,
    USER_INFO_SAVE
} from '../../constants/actionTypes';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    partner: null,
    templates: null,
    portfolios: null,
    userinfo: null
};

type AuthAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean, partner?: {} | null, templates?: {} | null, portfolios?: {} | null, userinfo?: {} | null, saving?: boolean };

const Auth = (state:State = INIT_STATE, action: AuthAction) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, user: null };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case NEW_PASSWORD:
            return { ...state, loading: true };
        case NEW_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case NEW_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PARTNER_VERIFY:
            return { ...state, loading: false };
        case PARTNER_VERIFY_SUCCESS:
            return { ...state, partner: action.payload, loading: false, error: null };
        case PARTNER_VERIFY_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PARTNER_TEMPLATE_LIST:
            return { ...state, loading: true };
        case PARTNER_TEMPLATE_LIST_SUCCESS:
            return { ...state, templates: action.payload, loading: false, error: null };
        case PARTNER_TEMPLATE_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PARTNER_PORTFOLIO_LIST:
            return { ...state, loading: true };
        case PARTNER_PORTFOLIO_LIST_SUCCESS:
            return { ...state, portfolios: action.payload, loading: false, error: null };
        case PARTNER_PORTFOLIO_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case USER_INFO:
            return { ...state, loading: true };
        case USER_INFO_SUCCESS:
            return { ...state, userinfo: action.payload, loading: false, saving: false, error: null };
        case USER_INFO_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case USER_INFO_SAVE:
            return { ...state, loading: true, saving: true };
        default: return { ...state };
    }
}

export default Auth;