// @flow
import {
    SALE_REPORT,
    SALE_REPORT_SUCCESS,
    SALE_REPORT_FAILED,
    ORDER_REPORT,
    ORDER_REPORT_SUCCESS,
    ORDER_REPORT_FAILED,
    ORDER_LIST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAILED,
    PRODUCT_LIST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAILED,
    CUSTOMER_LIST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAILED,
    STORE_ADDRESS,
    STORE_ADDRESS_SUCCESS,
    STORE_ADDRESS_FAILED,
    STORE_ADDRESS_SAVE,
    STORE_ORDER_NO,
    STORE_ORDER_NO_SUCCESS,
    STORE_ORDER_NO_FAILED,
    STORE_ORDER_NO_SAVE,
    STORE_SHIPPING,
    STORE_SHIPPING_SUCCESS,
    STORE_SHIPPING_FAILED,
    STORE_SHIPPING_FLATRATE_SAVE,
    STORE_SHIPPING_FREE,
    STORE_SHIPPING_FREE_SUCCESS,
    STORE_SHIPPING_FREE_FAILED,
    STORE_SHIPPING_FREE_SAVE,
    STORE_PAYMENT_BACS,
    STORE_PAYMENT_BACS_SUCCESS,
    STORE_PAYMENT_BACS_FAILED,
    STORE_PAYMENT_BACS_SAVE,
    STORE_PAYMENT_PAYPAL,
    STORE_PAYMENT_PAYPAL_SUCCESS,
    STORE_PAYMENT_PAYPAL_FAILED,
    STORE_PAYMENT_PAYPAL_SAVE
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    salereport: null,
    orderreport: null,
    orders: null,
    products: null,
    customers: null,
    address: null,
    orderno: null,
    shipping: null,
    shippingfree: null,
    bacs: null,
    paypal: null
};

type StoreAction = { type: string, payload: {} | string };
type State = { salereport?: {} | null, loading?: boolean, +value?: boolean, orderreport?: {} | null, orders?: {} | null, products?: {} | null, customers?: {} | null, address?: {} | null, orderno?: {} | null, shipping?: {} | null, shippingfree?: {} | null, bacs?: {} | null, paypal?: {} | null};

const Store = (state:State = INIT_STATE, action: StoreAction) => {
    switch (action.type) {
        case SALE_REPORT:
            return { ...state, loading: true };
        case SALE_REPORT_SUCCESS:
            return { ...state, salereport: action.payload, loading: false, error: null };
        case SALE_REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ORDER_REPORT:
            return { ...state, loading: true };
        case ORDER_REPORT_SUCCESS:
            return { ...state, orderreport: action.payload, loading: false, error: null };
        case ORDER_REPORT_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ORDER_LIST:
            return { ...state, loading: true };
        case ORDER_LIST_SUCCESS:
            return { ...state, orders: action.payload, loading: false, error: null };
        case ORDER_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case PRODUCT_LIST:
            return { ...state, loading: true };
        case PRODUCT_LIST_SUCCESS:
            return { ...state, products: action.payload, loading: false, error: null };
        case PRODUCT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case CUSTOMER_LIST:
            return { ...state, loading: true };
        case CUSTOMER_LIST_SUCCESS:
            return { ...state, customers: action.payload, loading: false, error: null };
        case CUSTOMER_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case STORE_ADDRESS:
            return { ...state, loading: true };
        case STORE_ADDRESS_SAVE:
            return { ...state, saving: true };
        case STORE_ADDRESS_SUCCESS:
            return { ...state, address: action.payload, loading: false, saving: false, error: null };
        case STORE_ADDRESS_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case STORE_ORDER_NO:
            return { ...state, loading: true };
        case STORE_ORDER_NO_SAVE:
            return { ...state, saving: true };
        case STORE_ORDER_NO_SUCCESS:
            return { ...state, orderno: action.payload, loading: false, saving: false, error: null };
        case STORE_ORDER_NO_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case STORE_SHIPPING:
            return { ...state, loading: true };
        case STORE_SHIPPING_FLATRATE_SAVE:
            return { ...state, saving: true };
        case STORE_SHIPPING_SUCCESS:
            return { ...state, shipping: action.payload, loading: false, saving: false, error: null };
        case STORE_SHIPPING_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case STORE_SHIPPING_FREE:
            return { ...state, loading: true };
        case STORE_SHIPPING_FREE_SAVE:
            return { ...state, saving: true };
        case STORE_SHIPPING_FREE_SUCCESS:
            return { ...state, shippingfree: action.payload, loading: false, saving: false, error: null };
        case STORE_SHIPPING_FREE_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case STORE_PAYMENT_BACS:
            return { ...state, loading: true };
        case STORE_PAYMENT_BACS_SAVE:
            return { ...state, saving: true };
        case STORE_PAYMENT_BACS_SUCCESS:
            return { ...state, bacs: action.payload, loading: false, saving: false, error: null };
        case STORE_PAYMENT_BACS_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        case STORE_PAYMENT_PAYPAL:
            return { ...state, loading: true };
        case STORE_PAYMENT_PAYPAL_SAVE:
            return { ...state, saving: true };
        case STORE_PAYMENT_PAYPAL_SUCCESS:
            return { ...state, paypal: action.payload, loading: false, saving: false, error: null };
        case STORE_PAYMENT_PAYPAL_FAILED:
            return { ...state, error: action.payload, loading: false, saving: false };
        default: return { ...state };
    }
}

export default Store;