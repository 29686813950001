// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    SALE_REPORT,
    ORDER_REPORT,
    ORDER_LIST,
    PRODUCT_LIST,
    CUSTOMER_LIST,
    STORE_ADDRESS,
    STORE_ADDRESS_SAVE,
    STORE_ORDER_NO,
    STORE_ORDER_NO_SAVE,
    STORE_SHIPPING,
    STORE_SHIPPING_FLATRATE_SAVE,
    STORE_SHIPPING_FREE,
    STORE_SHIPPING_FREE_SAVE,
    STORE_PAYMENT_BACS,
    STORE_PAYMENT_BACS_SAVE,
    STORE_PAYMENT_PAYPAL,
    STORE_PAYMENT_PAYPAL_SAVE
} from '../../constants/actionTypes';


import {
    saleReportSuccess,
    saleReportFailed,
    orderReportSuccess,
    orderReportFailed,
    orderListSuccess,
    orderListFailed,
    productListSuccess,
    productListFailed,
    customerListSuccess,
    customerListFailed,
    storeAddressSuccess,
    storeAddressFailed,
    storeOrderNoSuccess,
    storeOrderNoFailed,
    storeShippingSuccess,
    storeShippingFailed,
    storeShippingFreeSuccess,
    storeShippingFreeFailed,
    storePaymentBacsSuccess,
    storePaymentBacsFailed,
    storePaymentPaypalSuccess,
    storePaymentPaypalFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* salereport({ payload: { siteid,period,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/store/${siteid}/reports/sales/${period}`, options);
        if(response['status']){
            yield put(saleReportSuccess(response['data']));
        }
        else{
            yield put(saleReportFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(saleReportFailed(message));
    }
}

function* orderreport({ payload: { siteid,period,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/reports/orders/${period}`, options);
        if(response['status']){
            yield put(orderReportSuccess(response['data']));
        }
        else{
            yield put(orderReportFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(orderReportFailed(message));
    }
}

function* orderlist({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/store/${siteid}/orders`, options);
        if(response['status']){
            yield put(orderListSuccess(response['data']));
        }
        else{
            yield put(orderListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(orderListFailed(message));
    }
}

function* productlist({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/store/${siteid}/products`, options);
        if(response['status']){
            yield put(productListSuccess(response['data']));
        }
        else{
            yield put(productListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(productListFailed(message));
    }
}

function* customerlist({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/customers`, options);
        if(response['status']){
            yield put(customerListSuccess(response['data']));
        }
        else{
            yield put(customerListFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(customerListFailed(message));
    }
}

function* storeaddress({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/address`, options);
        if(response['status']){
            yield put(storeAddressSuccess(response['data']));
        }
        else{
            yield put(storeAddressFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeAddressFailed(message));
    }
}

function* storeaddresssave({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/address`, options);
        if(response['status']){
            yield put(storeAddressSuccess(response['data']));
        }
        else{
            yield put(storeAddressFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeAddressFailed(message));
    }
}

function* storeorderno({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/ordernumber`, options);
        if(response['status']){
            yield put(storeOrderNoSuccess(response['data']));
        }
        else{
            yield put(storeOrderNoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeOrderNoFailed(message));
    }
}

function* storeordernosave({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/ordernumber`, options);
        if(response['status']){
            yield put(storeOrderNoSuccess(response['data']));
        }
        else{
            yield put(storeOrderNoFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeOrderNoFailed(message));
    }
}

function* storeshipping({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/shipping`, options);
        if(response['status']){
            yield put(storeShippingSuccess(response['data']));
        }
        else{
            yield put(storeShippingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeShippingFailed(message));
    }
}

function* storeshippingflatrate({ payload: { siteid,data,method,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: method,
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/shipping/flatrate`, options);
        if(response['status']){
            yield put(storeShippingSuccess(response['data']));
        }
        else{
            yield put(storeShippingFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeShippingFailed(message));
    }
}

function* storeshippingfree({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/shipping`, options);
        if(response['status']){
            yield put(storeShippingFreeSuccess(response['data']));
        }
        else{
            yield put(storeShippingFreeFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeShippingFreeFailed(message));
    }
}

function* storeshippingfreesave({ payload: { siteid,data,method,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: method,
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/shipping/free`, options);
        if(response['status']){
            yield put(storeShippingFreeSuccess(response['data']));
        }
        else{
            yield put(storeShippingFreeFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storeShippingFreeFailed(message));
    }
}

function* storepaymentbacs({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/payment/bacs`, options);
        if(response['status']){
            yield put(storePaymentBacsSuccess(response['data']));
        }
        else{
            yield put(storePaymentBacsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storePaymentBacsFailed(message));
    }
}

function* storepaymentbacssave({ payload: { siteid,data,method,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: method,
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/payment/bacs`, options);
        if(response['status']){
            yield put(storePaymentBacsSuccess(response['data']));
        }
        else{
            yield put(storePaymentBacsFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storePaymentBacsFailed(message));
    }
}

function* storepaymentpaypal({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/payment/paypal`, options);
        console.log(response);
        if(response['status']){
            yield put(storePaymentPaypalSuccess(response['data']));
        }
        else{
            yield put(storePaymentPaypalFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storePaymentPaypalFailed(message));
    }
}

function* storepaymentpaypalsave({ payload: { siteid,data,token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgStoreApiUrl}/wp/${siteid}/payment/paypal`, options);
        if(response['status']){
            yield put(storePaymentPaypalSuccess(response['data']));
        }
        else{
            yield put(storePaymentPaypalFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(storePaymentPaypalFailed(message));
    }
}

export function* watchSaleReport():any {
    yield takeEvery(SALE_REPORT, salereport);
}
export function* watchOrderReport():any {
    yield takeEvery(ORDER_REPORT, orderreport);
}
export function* watchOrderList():any {
    yield takeEvery(ORDER_LIST, orderlist);
}
export function* watchProductList():any {
    yield takeEvery(PRODUCT_LIST, productlist);
}
export function* watchCustomerList():any {
    yield takeEvery(CUSTOMER_LIST, customerlist);
}
export function* watchStoreAddress():any {
    yield takeEvery(STORE_ADDRESS, storeaddress);
}
export function* watchStoreAddressSave():any {
    yield takeEvery(STORE_ADDRESS_SAVE, storeaddresssave);
}
export function* watchStoreOrderNo():any {
    yield takeEvery(STORE_ORDER_NO, storeorderno);
}
export function* watchStoreOrderNoSave():any {
    yield takeEvery(STORE_ORDER_NO_SAVE, storeordernosave);
}
export function* watchStoreShipping():any {
    yield takeEvery(STORE_SHIPPING, storeshipping);
}
export function* watchStoreShippingFlatrate():any {
    yield takeEvery(STORE_SHIPPING_FLATRATE_SAVE, storeshippingflatrate);
}
export function* watchStoreShippingFree():any {
    yield takeEvery(STORE_SHIPPING_FREE, storeshippingfree);
}
export function* watchStoreShippingFreeSave():any {
    yield takeEvery(STORE_SHIPPING_FREE_SAVE, storeshippingfreesave);
}
export function* watchPaymentBacs():any {
    yield takeEvery(STORE_PAYMENT_BACS, storepaymentbacs);
}
export function* watchPaymentBacsSave():any {
    yield takeEvery(STORE_PAYMENT_BACS_SAVE, storepaymentbacssave);
}
export function* watchPaymentPaypal():any {
    yield takeEvery(STORE_PAYMENT_PAYPAL, storepaymentpaypal);
}
export function* watchPaymentPaypalSave():any {
    yield takeEvery(STORE_PAYMENT_PAYPAL_SAVE, storepaymentpaypalsave);
}

function* storeSaga():any {
    yield all([
        fork(watchSaleReport),
        fork(watchOrderReport),
        fork(watchOrderList),
        fork(watchProductList),
        fork(watchCustomerList),
        fork(watchStoreAddress),
        fork(watchStoreAddressSave),
        fork(watchStoreOrderNo),
        fork(watchStoreOrderNoSave),
        fork(watchStoreShipping),
        fork(watchStoreShippingFlatrate),
        fork(watchStoreShippingFree),
        fork(watchStoreShippingFreeSave),
        fork(watchPaymentBacs),
        fork(watchPaymentBacsSave),
        fork(watchPaymentPaypal),
        fork(watchPaymentPaypalSave)
    ]);
}

export default storeSaga;