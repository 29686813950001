import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import siteSaga from './site/saga';
import storeSaga from './store/saga';
import pdpaSaga from './pdpa/saga';
import dashboardSaga from './dashboard/saga';
import generalSaga from './general/saga';
import seoSaga from './seo/saga'
import formSaga from './form/saga'
import integrationsSaga from './integrations/saga'
import editorSaga from './editor/saga'
import hostingSaga from './hosting/saga'


export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        siteSaga(),
        dashboardSaga(),
        generalSaga(),
        seoSaga(),
        formSaga(),
        integrationsSaga(),
        editorSaga(),
        hostingSaga(),
        storeSaga(),
        pdpaSaga()
    ]);
}
