import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Site from './site/reducers';
import Store from './store/reducers';
import Pdpa from './pdpa/reducers';
import Dashboard from './dashboard/reducers';
import DashboardSummary from './dashboard/reducers/summary';
import DashboardUserOnline from './dashboard/reducers/useronline';
import DashboardHits from './dashboard/reducers/hits';
import DashboardBrowser from './dashboard/reducers/browser';
import DashboardPlatform from './dashboard/reducers/platform';
import GeneralBasicInfo from './general/reducers/basicinfo';
import GeneralContactInfo from './general/reducers/conntactinfo';
import GeneralBusinessInfo from './general/reducers/business';
import GeneralRegional from './general/reducers/regional';
import SeoGoogleVerify from './seo/reducers/googleverify';
import FormEmailSetting from './form/reducers/emailsetting';
import FormSmtpSetting from './form/reducers/smtpsetting';
import FormCaptChaSetting from './form/reducers/captchasetting';
import IntegrationsGtag from './integrations/reducers/gtag';
import IntegrationsChatWidget from './integrations/reducers/chatwidget';
import IntegrationsFbpixel from './integrations/reducers/fbpixel';
import IntegrationsLineNotify from './integrations/reducers/linenotify';
import IntegrationsCloudFlare from './integrations/reducers/cloudflare';
import IntegrationsGA from './integrations/reducers/ga';
import EditorCollaborators from './editor/reducers/collaborators';
import HostingPlan from './hosting/reducers/plan';
import HostingPlanDNS from './hosting/reducers/dns';
import HostingPlanCheckDomain from './hosting/reducers/checkdomain';
import HostingPlanPublish from './hosting/reducers/publish';

export default combineReducers({
    Auth,
    Site,
    Store,
    Pdpa,
    Dashboard,
    DashboardSummary,
    DashboardUserOnline,
    DashboardHits,
    DashboardBrowser,
    DashboardPlatform,
    GeneralBasicInfo,
    GeneralContactInfo,
    GeneralBusinessInfo,
    GeneralRegional,
    SeoGoogleVerify,
    FormEmailSetting,
    FormSmtpSetting,
    FormCaptChaSetting,
    IntegrationsGtag,
    IntegrationsChatWidget,
    IntegrationsFbpixel,
    IntegrationsLineNotify,
    EditorCollaborators,
    HostingPlan,
    HostingPlanDNS,
    HostingPlanCheckDomain,
    HostingPlanPublish,
    IntegrationsCloudFlare,
    IntegrationsGA,
});