// @flow
import {
    COOKIE_BAR,
    COOKIE_BAR_SUCCESS,
    COOKIE_BAR_FAILED,
    COOKIE_BAR_SAVE
} from '../../constants/actionTypes';

const INIT_STATE = {
    loading: false,
    cookiebar: null
};

type PdpaAction = { type: string, payload: {} | string };
type State = { cookiebar?: {} | null, loading?: boolean, +value?: boolean};

const Pdpa = (state:State = INIT_STATE, action: PdpaAction) => {
    switch (action.type) {
        case COOKIE_BAR:
            return { ...state, loading: true };
        case COOKIE_BAR_SAVE:
            return { ...state, saving: true };
        case COOKIE_BAR_SUCCESS:
            return { ...state, cookiebar: action.payload, loading: false, saving: false, error: null };
        case COOKIE_BAR_FAILED:
            return { ...state, error: action.payload, saving: false, loading: false };
        default: return { ...state };
    }
}

export default Pdpa;