// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    INTEGRATIONS_GTAG,
    INTEGRATIONS_GTAG_SAVE,
    INTEGRATIONS_CHATWIDGET,
    INTEGRATIONS_CHATWIDGET_SAVE,
    INTEGRATIONS_FBPIXEL,
    INTEGRATIONS_FBPIXEL_SAVE,
    INTEGRATIONS_LINENOTIFY,
    INTEGRATIONS_LINENOTIFY_SAVE,
    INTEGRATIONS_CLOUDFLARE,
    INTEGRATIONS_CLOUDFLARE_SAVE,
    INTEGRATIONS_GA,
    INTEGRATIONS_GA_SAVE,
} from '../../constants/actionTypes';


import {
    integrationsGtagSuccess,
    integrationsGtagFailed,
    integrationsChatWidgetSuccess,
    integrationsChatWidgetFailed,
    integrationsFbpixelSuccess,
    integrationsFbpixelFailed,
    integrationsLineNotifySuccess,
    integrationsLineNotifyFailed,
    integrationsCloudFlareSuccess,
    integrationsCloudFlareFailed,
    integrationsGASuccess,
    integrationsGAFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}

function* integrationsgtag({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/gtag`, options);
        if(response['status']){
            yield put(integrationsGtagSuccess(response['data']));
        }
        else{
            yield put(integrationsGtagFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsGtagFailed(message));
    }
}

function* integrationsgtagsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/gtag`, options);
        if(response['status']){
            yield put(integrationsGtagSuccess(response['data']));
        }
        else{
            yield put(integrationsGtagFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsGtagFailed(message));
    }
}

function* integrationsga({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/ga`, options);
        if(response['status']){
            yield put(integrationsGASuccess(response['data']));
        }
        else{
            yield put(integrationsGAFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsGAFailed(message));
    }
}

function* integrationsgasave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/ga`, options);
        if(response['status']){
            yield put(integrationsGASuccess(response['data']));
        }
        else{
            yield put(integrationsGAFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsGAFailed(message));
    }
}

function* integrationschatwidget({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/chatwidget`, options);
        if(response['status']){
            yield put(integrationsChatWidgetSuccess(response['data']));
        }
        else{
            yield put(integrationsChatWidgetFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsChatWidgetFailed(message));
    }
}

function* integrationschatwidgetsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/site/${site_id}/chatwidget`, options);
        if(response['status']){
            yield put(integrationsChatWidgetSuccess(response['data']));
        }
        else{
            yield put(integrationsChatWidgetFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsChatWidgetFailed(message));
    }
}

function* integrationsfbpixel({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/fbpixel`, options);
        if(response['status']){
            yield put(integrationsFbpixelSuccess(response['data']));
        }
        else{
            yield put(integrationsFbpixelFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsFbpixelFailed(message));
    }
}

function* integrationsfbpixelsave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/fbpixel`, options);
        if(response['status']){
            yield put(integrationsFbpixelSuccess(response['data']));
        }
        else{
            yield put(integrationsFbpixelFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsFbpixelFailed(message));
    }
}

function* integrationslinenotify({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/linenotify`, options);
        if(response['status']){
            console.log(response['data']);
            yield put(integrationsLineNotifySuccess(response['data']));
        }
        else{
            yield put(integrationsLineNotifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsLineNotifyFailed(message));
    }
}

function* integrationslinenotifysave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/linenotify`, options);
        if(response['status']){
            yield put(integrationsLineNotifySuccess(response['data']));
        }
        else{
            yield put(integrationsLineNotifyFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsLineNotifyFailed(message));
    }
}

function* integrationscloudflare({ payload: { site_id, token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/cloudflare`, options);
        if(response['status']){
            console.log(response['data']);
            yield put(integrationsCloudFlareSuccess(response['data']));
        }
        else{
            yield put(integrationsCloudFlareFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsCloudFlareFailed(message));
    }
}

function* integrationscloudflaresave({ payload: { site_id, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgApiUrl}/wp/${site_id}/cloudflare`, options);
        if(response['status']){
            yield put(integrationsCloudFlareSuccess(response['data']));
        }
        else{
            yield put(integrationsCloudFlareFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(integrationsCloudFlareFailed(message));
    }
}

export function* watchIntegrationsGtag():any {
    yield takeEvery(INTEGRATIONS_GTAG, integrationsgtag);
}

export function* watchIntegrationsGtagSave():any {
    yield takeEvery(INTEGRATIONS_GTAG_SAVE, integrationsgtagsave);
}

export function* watchIntegrationsChatWidget():any {
    yield takeEvery(INTEGRATIONS_CHATWIDGET, integrationschatwidget);
}

export function* watchIntegrationsChatWidgetSave():any {
    yield takeEvery(INTEGRATIONS_CHATWIDGET_SAVE, integrationschatwidgetsave);
}

export function* watchIntegrationsFbpixel():any {
    yield takeEvery(INTEGRATIONS_FBPIXEL, integrationsfbpixel);
}

export function* watchIntegrationsFbpixelSave():any {
    yield takeEvery(INTEGRATIONS_FBPIXEL_SAVE, integrationsfbpixelsave);
}

export function* watchIntegrationsLineNotify():any {
    yield takeEvery(INTEGRATIONS_LINENOTIFY, integrationslinenotify);
}

export function* watchIntegrationsLineNotifySave():any {
    yield takeEvery(INTEGRATIONS_LINENOTIFY_SAVE, integrationslinenotifysave);
}

export function* watchIntegrationsCloudFlare():any {
    yield takeEvery(INTEGRATIONS_CLOUDFLARE, integrationscloudflare);
}

export function* watchIntegrationsCloudFlareSave():any {
    yield takeEvery(INTEGRATIONS_CLOUDFLARE_SAVE, integrationscloudflaresave);
}

export function* watchIntegrationsGA():any {
    yield takeEvery(INTEGRATIONS_GA, integrationsga);
}

export function* watchIntegrationsGASave():any {
    yield takeEvery(INTEGRATIONS_GA_SAVE, integrationsgasave);
}


function* formSaga():any {
    yield all([
        fork(watchIntegrationsGtag),
        fork(watchIntegrationsGtagSave),
        fork(watchIntegrationsChatWidget),
        fork(watchIntegrationsChatWidgetSave),
        fork(watchIntegrationsFbpixel),
        fork(watchIntegrationsFbpixelSave),
        fork(watchIntegrationsLineNotify),
        fork(watchIntegrationsLineNotifySave),
        fork(watchIntegrationsCloudFlare),
        fork(watchIntegrationsCloudFlareSave),
        fork(watchIntegrationsGA),
        fork(watchIntegrationsGASave),
    ]);
}

export default formSaga;