// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { myConfig } from '../config';

import {
    COOKIE_BAR,
    COOKIE_BAR_SAVE
} from '../../constants/actionTypes';


import {
    cookieBarSuccess,
    cookieBarFailed
} from './actions';


/**
 * Fetch data from given url
 * @param {*} url 
 * @param {*} options 
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => { throw error });
}


function* cookiebar({ payload: { siteid,token } }) {
    const options = {
        body: JSON.stringify(),
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgPDPAApiUrl}/wp/${siteid}/cookiebar`, options);
        if(response['status']){
            yield put(cookieBarSuccess(response['data']));
        }
        else{
            yield put(cookieBarFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(cookieBarFailed(message));
    }
}

function* cookiebarsave({ payload: { siteid, data, token } }) {
    const options = {
        body: JSON.stringify({ data }),
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'X-API-KEY': myConfig.lgApiKey, 'Authorization': token}
    };

    try {
        const response = yield call(fetchJSON, `${myConfig.lgPDPAApiUrl}/wp/${siteid}/cookiebar`, options);
        if(response['status']){
            yield put(cookieBarSuccess(response['data']));
        }
        else{
            yield put(cookieBarFailed(response['message']));
            
        }
        
    } catch (error) {
        let message;
        switch (error.status) {
            case 500: message = 'Internal Server Error'; break;
            case 401: message = 'Invalid credentials'; break;
            default: message = error;
        }
        yield put(cookieBarFailed(message));
    }
}

export function* watchCookieBar():any {
    yield takeEvery(COOKIE_BAR, cookiebar);
}

export function* watchCookieBarSave():any {
    yield takeEvery(COOKIE_BAR_SAVE, cookiebarsave);
}

function* pdpaSaga():any {
    yield all([
        fork(watchCookieBar),
        fork(watchCookieBarSave)
    ]);
}

export default pdpaSaga;