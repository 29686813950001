/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED';
export const USER_INFO = 'USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILED = 'USER_INFO_FAILED';
export const USER_INFO_SAVE = 'USER_INFO_SAVE';

/* FrontEnd */
export const PARTNER_TEMPLATE_LIST = 'PARTNER_TEMPLATE_LIST';
export const PARTNER_TEMPLATE_LIST_SUCCESS = 'PARTNER_TEMPLATE_LIST_SUCCESS';
export const PARTNER_TEMPLATE_LIST_FAILED = 'PARTNER_TEMPLATE_LIST_FAILED';
export const PARTNER_PORTFOLIO_LIST = 'PARTNER_PORTFOLIO_LIST';
export const PARTNER_PORTFOLIO_LIST_SUCCESS = 'PARTNER_PORTFOLIO_LIST_SUCCESS';
export const PARTNER_PORTFOLIO_LIST_FAILED = 'PARTNER_PORTFOLIO_LIST_FAILED';

/* Site */
export const SITE_LIST = 'SITE_LIST';
export const SITE_LIST_SUCCESS = 'SITE_LIST_SUCCESS';
export const SITE_LIST_FAILED = 'SITE_LIST_FAILED';
export const SITE_SCREENSHOT = 'SITE_SCREENSHOT';
export const SITE_SCREENSHOT_SUCCESS = 'SITE_SCREENSHOT_SUCCESS';
export const SITE_SCREENSHOT_FAILED = 'SITE_SCREENSHOT_FAILED';
export const SITE_CLEAR = 'SITE_CLEAR';
export const SITE_BUILDER = 'SITE_BUILDER';
export const SITE_BUILDER_SUCCESS = 'SITE_BUILDER_SUCCESS';
export const SITE_BUILDER_FAILED = 'SITE_BUILDER_FAILED';
export const SITE_ACCESS_TOKEN = 'SITE_ACCESS_TOKEN';
export const SITE_ACCESS_TOKEN_SUCCESS = 'SITE_ACCESS_TOKEN_SUCCESS';
export const SITE_ACCESS_TOKEN_FAILED = 'SITE_ACCESS_TOKEN_FAILED';
export const SITE_TEMPLATE_LIST = 'SITE_TEMPLATE_LIST';
export const SITE_TEMPLATE_LIST_SUCCESS = 'SITE_TEMPLATE_LIST_SUCCESS';
export const SITE_TEMPLATE_LIST_FAILED = 'SITE_TEMPLATE_LIST_FAILED';
export const SITE_SALEPAGE_PACKAGE_LIST = 'SITE_SALEPAGE_PACKAGE_LIST';
export const SITE_SALEPAGE_PACKAGE_LIST_SUCCESS = 'SITE_SALEPAGE_PACKAGE_LIST_SUCCESS';
export const SITE_SALEPAGE_PACKAGE_LIST_FAILED = 'SITE_SALEPAGE_PACKAGE_LIST_FAILED';

/* Dashboard */
export const DASHBOARD_DISK_USAGE = 'DASHBOARD_DISK_USAGE';
export const DASHBOARD_DISK_USAGE_SUCCESS = 'DASHBOARD_DISK_USAGE_SUCCESS';
export const DASHBOARD_DISK_USAGE_FAILED = 'DASHBOARD_DISK_USAGE_FAILED';
export const DASHBOARD_SUMMARY = 'DASHBOARD_SUMMARY';
export const DASHBOARD_SUMMARY_SUCCESS = 'DASHBOARD_SUMMARY_SUCCESS';
export const DASHBOARD_SUMMARY_FAILED = 'DASHBOARD_SUMMARY_FAILED';
export const DASHBOARD_USERONLINE = 'DASHBOARD_USERONLINE';
export const DASHBOARD_USERONLINE_SUCCESS = 'DASHBOARD_USERONLINE_SUCCESS';
export const DASHBOARD_USERONLINE_FAILED = 'DASHBOARD_USERONLINE_FAILED';
export const DASHBOARD_HITS = 'DASHBOARD_HITS';
export const DASHBOARD_HITS_SUCCESS = 'DASHBOARD_HITS_SUCCESS';
export const DASHBOARD_HITS_FAILED = 'DASHBOARD_HITS_FAILED';
export const DASHBOARD_BROWSER = 'DASHBOARD_BROWSER';
export const DASHBOARD_BROWSER_SUCCESS = 'DASHBOARD_BROWSER_SUCCESS';
export const DASHBOARD_BROWSER_FAILED = 'DASHBOARD_BROWSER_FAILED';
export const DASHBOARD_PLATFORM = 'DASHBOARD_PLATFORM';
export const DASHBOARD_PLATFORM_SUCCESS = 'DASHBOARD_PLATFORM_SUCCESS';
export const DASHBOARD_PLATFORM_FAILED = 'DASHBOARD_PLATFORM_FAILED';

/* GENERAL */
export const GENERAL_BASIC_INFO = 'GENERAL_BASIC_INFO';
export const GENERAL_BASIC_INFO_SUCCESS = 'GENERAL_BASIC_INFO_SUCCESS';
export const GENERAL_BASIC_INFO_FAILED = 'GENERAL_BASIC_INFO_FAILED';
export const GENERAL_BASIC_INFO_SAVE = 'GENERAL_BASIC_INFO_SAVE';
export const GENERAL_CONTACT_INFO = 'GENERAL_CONTACT_INFO';
export const GENERAL_CONTACT_INFO_SUCCESS = 'GENERAL_CONTACT_INFO_SUCCESS';
export const GENERAL_CONTACT_INFO_FAILED = 'GENERAL_CONTACT_INFO_FAILED';
export const GENERAL_CONTACT_INFO_SAVE = 'GENERAL_CONTACT_INFO_SAVE';
export const GENERAL_BUSINESS_INFO = 'GENERAL_BUSINESS_INFO';
export const GENERAL_BUSINESS_INFO_SUCCESS = 'GENERAL_BUSINESS_INFO_SUCCESS';
export const GENERAL_BUSINESS_INFO_FAILED = 'GENERAL_BUSINESS_INFO_FAILED';
export const GENERAL_BUSINESS_INFO_SAVE = 'GENERAL_BUSINESS_INFO_SAVE';
export const GENERAL_REGIONAL = 'GENERAL_REGIONAL';
export const GENERAL_REGIONAL_SUCCESS = 'GENERAL_REGIONAL_SUCCESS';
export const GENERAL_REGIONAL_FAILED = 'GENERAL_REGIONAL_FAILED';
export const GENERAL_REGIONAL_SAVE = 'GENERAL_REGIONAL_SAVE';

/* SEO */
export const SEO_GOOGLE_VERIFY = 'SEO_GOOGLE_VERIFY';
export const SEO_GOOGLE_VERIFY_SUCCESS = 'SEO_GOOGLE_VERIFY_SUCCESS';
export const SEO_GOOGLE_VERIFY_FAILED = 'SEO_GOOGLE_VERIFY_FAILED';
export const SEO_GOOGLE_VERIFY_SAVE = 'SEO_GOOGLE_VERIFY_SAVE';

/* FORM */
export const FORM_EMAIL_SETTING = 'FORM_EMAIL_SETTING';
export const FORM_EMAIL_SETTING_SUCCESS = 'FORM_EMAIL_SETTING_SUCCESS';
export const FORM_EMAIL_SETTING_FAILED = 'FORM_EMAIL_SETTING_FAILED';
export const FORM_EMAIL_SETTING_SAVE = 'FORM_EMAIL_SETTING_SAVE';
export const FORM_SMTP_SETTING = 'FORM_SMTP_SETTING';
export const FORM_SMTP_SETTING_SUCCESS = 'FORM_SMTP_SETTING_SUCCESS';
export const FORM_SMTP_SETTING_FAILED = 'FORM_SMTP_SETTING_FAILED';
export const FORM_SMTP_SETTING_SAVE = 'FORM_SMTP_SETTING_SAVE';
export const FORM_CAPTCHA_SETTING = 'FORM_CAPTCHA_SETTING';
export const FORM_CAPTCHA_SETTING_SUCCESS = 'FORM_CAPTCHA_SETTING_SUCCESS';
export const FORM_CAPTCHA_SETTING_FAILED = 'FORM_CAPTCHA_SETTING_FAILED';
export const FORM_CAPTCHA_SETTING_SAVE = 'FORM_CAPTCHA_SETTING_SAVE';

/* Integrations */
export const INTEGRATIONS_GTAG = 'INTEGRATIONS_GTAG';
export const INTEGRATIONS_GTAG_SUCCESS = 'INTEGRATIONS_GTAG_SUCCESS';
export const INTEGRATIONS_GTAG_FAILED = 'INTEGRATIONS_GTAG_FAILED';
export const INTEGRATIONS_GTAG_SAVE = 'INTEGRATIONS_GTAG_SAVE';
export const INTEGRATIONS_CHATWIDGET = 'INTEGRATIONS_CHATWIDGET';
export const INTEGRATIONS_CHATWIDGET_SUCCESS = 'INTEGRATIONS_CHATWIDGET_SUCCESS';
export const INTEGRATIONS_CHATWIDGET_FAILED = 'INTEGRATIONS_CHATWIDGET_FAILED';
export const INTEGRATIONS_CHATWIDGET_SAVE = 'INTEGRATIONS_CHATWIDGET_SAVE';
export const INTEGRATIONS_FBPIXEL = 'INTEGRATIONS_FBPIXEL';
export const INTEGRATIONS_FBPIXEL_SUCCESS = 'INTEGRATIONS_FBPIXEL_SUCCESS';
export const INTEGRATIONS_FBPIXEL_FAILED = 'INTEGRATIONS_FBPIXEL_FAILED';
export const INTEGRATIONS_FBPIXEL_SAVE = 'INTEGRATIONS_FBPIXEL_SAVE';
export const INTEGRATIONS_LINENOTIFY = 'INTEGRATIONS_LINENOTIFY';
export const INTEGRATIONS_LINENOTIFY_SUCCESS = 'INTEGRATIONS_LINENOTIFY_SUCCESS';
export const INTEGRATIONS_LINENOTIFY_FAILED = 'INTEGRATIONS_LINENOTIFY_FAILED';
export const INTEGRATIONS_LINENOTIFY_SAVE = 'INTEGRATIONS_LINENOTIFY_SAVE';
export const INTEGRATIONS_CLOUDFLARE = 'INTEGRATIONS_CLOUDFLARE';
export const INTEGRATIONS_CLOUDFLARE_SUCCESS = 'INTEGRATIONS_CLOUDFLARE_SUCCESS';
export const INTEGRATIONS_CLOUDFLARE_FAILED = 'INTEGRATIONS_CLOUDFLARE_FAILED';
export const INTEGRATIONS_CLOUDFLARE_SAVE = 'INTEGRATIONS_CLOUDFLARE_SAVE';
export const INTEGRATIONS_GA = 'INTEGRATIONS_GA';
export const INTEGRATIONS_GA_SUCCESS = 'INTEGRATIONS_GA_SUCCESS';
export const INTEGRATIONS_GA_FAILED = 'INTEGRATIONS_GA_FAILED';
export const INTEGRATIONS_GA_SAVE = 'INTEGRATIONS_GA_SAVE';

/* Editor */
export const EDITOR_COLLABORATORS = 'EDITOR_COLLABORATORS';
export const EDITOR_COLLABORATORS_SUCCESS = 'EDITOR_COLLABORATORS_SUCCESS';
export const EDITOR_COLLABORATORS_FAILED = 'EDITOR_COLLABORATORS_FAILED';
export const EDITOR_COLLABORATORS_SAVE = 'EDITOR_COLLABORATORS_SAVE';
export const EDITOR_COLLABORATORS_DELETE = 'EDITOR_COLLABORATORS_DELETE';
export const EDITOR_TRANSFER_OWNER = 'EDITOR_TRANSFER_OWNER';

/* Hosting */
export const HOSTING_PLAN = 'HOSTING_PLAN';
export const HOSTING_PLAN_SUCCESS = 'HOSTING_PLAN_SUCCESS';
export const HOSTING_PLAN_FAILED = 'HOSTING_PLAN_FAILED';
export const HOSTING_PLAN_CHARGE = 'HOSTING_PLAN_CHARGE';
export const HOSTING_PLAN_CHARGE_SUCCESS = 'HOSTING_PLAN_CHARGE_SUCCESS';
export const HOSTING_PLAN_CHARGE_FAILED = 'HOSTING_PLAN_CHARGE_FAILED';
export const HOSTING_PLAN_BILLING = 'HOSTING_PLAN_BILLING';
export const HOSTING_PLAN_BILLING_SUCCESS = 'HOSTING_PLAN_BILLING_SUCCESS';
export const HOSTING_PLAN_BILLING_FAILED = 'HOSTING_PLAN_BILLING_FAILED';
export const HOSTING_PLAN_DNS = 'HOSTING_PLAN_DNS';
export const HOSTING_PLAN_DNS_SUCCESS = 'HOSTING_PLAN_DNS_SUCCESS';
export const HOSTING_PLAN_DNS_FAILED = 'HOSTING_PLAN_DNS_FAILED';
export const HOSTING_PLAN_CHECKDOMAIN = 'HOSTING_PLAN_CHECKDOMAIN';
export const HOSTING_PLAN_CHECKDOMAIN_SUCCESS = 'HOSTING_PLAN_CHECKDOMAIN_SUCCESS';
export const HOSTING_PLAN_CHECKDOMAIN_FAILED = 'HOSTING_PLAN_CHECKDOMAIN_FAILED';
export const HOSTING_PLAN_PUBLISH = 'HOSTING_PLAN_PUBLISH';
export const HOSTING_PLAN_PUBLISH_SUCCESS = 'HOSTING_PLAN_PUBLISH_SUCCESS';
export const HOSTING_PLAN_PUBLISH_FAILED = 'HOSTING_PLAN_PUBLISH_FAILED';
export const HOSTING_PLAN_BANKTRANSFER = 'HOSTING_PLAN_BANKTRANSFER';
export const HOSTING_PLAN_BANKTRANSFER_SUCCESS = 'HOSTING_PLAN_BANKTRANSFER_SUCCESS';
export const HOSTING_PLAN_BANKTRANSFER_FAILED = 'HOSTING_PLAN_BANKTRANSFER_FAILED';

/* Partner */
export const PARTNER_VERIFY = 'PARTNER_VERIFY';
export const PARTNER_VERIFY_SUCCESS = 'PARTNER_VERIFY_SUCCESS';
export const PARTNER_VERIFY_FAILED = 'PARTNER_VERIFY_FAILED';

/* Store */
export const SALE_REPORT = 'SALE_REPORT';
export const SALE_REPORT_SUCCESS = 'SALE_REPORT_SUCCESS';
export const SALE_REPORT_FAILED = 'SALE_REPORT_FAILED';
export const ORDER_REPORT = 'ORDER_REPORT';
export const ORDER_REPORT_SUCCESS = 'ORDER_REPORT_SUCCESS';
export const ORDER_REPORT_FAILED = 'ORDER_REPORT_FAILED';
export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILED = 'ORDER_LIST_FAILED';
export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILED = 'PRODUCT_LIST_FAILED';
export const CUSTOMER_LIST = 'CUSTOMER_LIST';
export const CUSTOMER_LIST_SUCCESS = 'CUSTOMER_LIST_SUCCESS';
export const CUSTOMER_LIST_FAILED = 'CUSTOMER_LIST_FAILED';
export const STORE_ADDRESS = 'STORE_ADDRESS';
export const STORE_ADDRESS_SUCCESS = 'STORE_ADDRESS_SUCCESS';
export const STORE_ADDRESS_FAILED = 'STORE_ADDRESS_FAILED';
export const STORE_ADDRESS_SAVE = 'STORE_ADDRESS_SAVE';
export const STORE_ORDER_NO = 'STORE_ORDER_NO';
export const STORE_ORDER_NO_SUCCESS = 'STORE_ORDER_NO_SUCCESS';
export const STORE_ORDER_NO_FAILED = 'STORE_ORDER_NO_FAILED';
export const STORE_ORDER_NO_SAVE = 'STORE_ORDER_NO_SAVE';
export const STORE_SHIPPING = 'STORE_SHIPPING';
export const STORE_SHIPPING_SUCCESS = 'STORE_SHIPPING_SUCCESS';
export const STORE_SHIPPING_FAILED = 'STORE_SHIPPING_FAILED';
export const STORE_SHIPPING_FLATRATE_SAVE = 'STORE_SHIPPING_FLATRATE_SAVE';
export const STORE_SHIPPING_FREE = 'STORE_SHIPPING_FREE';
export const STORE_SHIPPING_FREE_SUCCESS = 'STORE_SHIPPING_FREE_SUCCESS';
export const STORE_SHIPPING_FREE_FAILED = 'STORE_SHIPPING_FREE_FAILED';
export const STORE_SHIPPING_FREE_SAVE = 'STORE_SHIPPING_FREE_SAVE';
export const STORE_PAYMENT_BACS = 'STORE_PAYMENT_BACS';
export const STORE_PAYMENT_BACS_SUCCESS = 'STORE_PAYMENT_BACS_SUCCESS';
export const STORE_PAYMENT_BACS_FAILED = 'STORE_PAYMENT_BACS_FAILED';
export const STORE_PAYMENT_BACS_SAVE = 'STORE_PAYMENT_BACS_SAVE';
export const STORE_PAYMENT_PAYPAL = 'STORE_PAYMENT_PAYPAL';
export const STORE_PAYMENT_PAYPAL_SUCCESS = 'STORE_PAYMENT_PAYPAL_SUCCESS';
export const STORE_PAYMENT_PAYPAL_FAILED = 'STORE_PAYMENT_PAYPAL_FAILED';
export const STORE_PAYMENT_PAYPAL_SAVE = 'STORE_PAYMENT_PAYPAL_SAVE';

/* PDPA */
export const COOKIE_BAR = 'COOKIE_BAR';
export const COOKIE_BAR_SUCCESS = 'COOKIE_BAR_SUCCESS';
export const COOKIE_BAR_FAILED = 'COOKIE_BAR_FAILED';
export const COOKIE_BAR_SAVE = 'COOKIE_BAR_SAVE';