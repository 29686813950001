// @flow
import {
    SALE_REPORT,
    SALE_REPORT_SUCCESS,
    SALE_REPORT_FAILED,
    ORDER_REPORT,
    ORDER_REPORT_SUCCESS,
    ORDER_REPORT_FAILED,
    ORDER_LIST,
    ORDER_LIST_SUCCESS,
    ORDER_LIST_FAILED,
    PRODUCT_LIST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAILED,
    CUSTOMER_LIST,
    CUSTOMER_LIST_SUCCESS,
    CUSTOMER_LIST_FAILED,
    STORE_ADDRESS,
    STORE_ADDRESS_SUCCESS,
    STORE_ADDRESS_FAILED,
    STORE_ADDRESS_SAVE,
    STORE_ORDER_NO,
    STORE_ORDER_NO_SUCCESS,
    STORE_ORDER_NO_FAILED,
    STORE_ORDER_NO_SAVE,
    STORE_SHIPPING,
    STORE_SHIPPING_SUCCESS,
    STORE_SHIPPING_FAILED,
    STORE_SHIPPING_FLATRATE_SAVE,
    STORE_SHIPPING_FREE,
    STORE_SHIPPING_FREE_SUCCESS,
    STORE_SHIPPING_FREE_FAILED,
    STORE_SHIPPING_FREE_SAVE,
    STORE_PAYMENT_BACS,
    STORE_PAYMENT_BACS_SUCCESS,
    STORE_PAYMENT_BACS_FAILED,
    STORE_PAYMENT_BACS_SAVE,
    STORE_PAYMENT_PAYPAL,
    STORE_PAYMENT_PAYPAL_SUCCESS,
    STORE_PAYMENT_PAYPAL_FAILED,
    STORE_PAYMENT_PAYPAL_SAVE
} from '../../constants/actionTypes';

type StoreAction = { type: string, payload: {} | string };

export const saleReport = (siteid: string, period: string, token: string): StoreAction => ({
    type: SALE_REPORT,
    payload: {siteid,period,token}
});

export const saleReportSuccess = (salereport: string): StoreAction => ({
    type: SALE_REPORT_SUCCESS,
    payload: salereport
});

export const saleReportFailed = (error: string): StoreAction => ({
    type: SALE_REPORT_FAILED,
    payload: error
});

export const orderReport = (siteid: string, period: string, token: string): StoreAction => ({
    type: ORDER_REPORT,
    payload: {siteid,period,token}
});

export const orderReportSuccess = (salereport: string): StoreAction => ({
    type: ORDER_REPORT_SUCCESS,
    payload: salereport
});

export const orderReportFailed = (error: string): StoreAction => ({
    type: ORDER_REPORT_FAILED,
    payload: error
});

export const orderList = (siteid: string, data: string, token: string): StoreAction => ({
    type: ORDER_LIST,
    payload: {siteid,data,token}
});

export const orderListSuccess = (orders: string): StoreAction => ({
    type: ORDER_LIST_SUCCESS,
    payload: orders
});

export const orderListFailed = (error: string): StoreAction => ({
    type: ORDER_LIST_FAILED,
    payload: error
});

export const productList = (siteid: string, data: string, token: string): StoreAction => ({
    type: PRODUCT_LIST,
    payload: {siteid,data,token}
});

export const productListSuccess = (products: string): StoreAction => ({
    type: PRODUCT_LIST_SUCCESS,
    payload: products
});

export const productListFailed = (error: string): StoreAction => ({
    type: PRODUCT_LIST_FAILED,
    payload: error
});

export const customerList = (siteid: string, data: string, token: string): StoreAction => ({
    type: CUSTOMER_LIST,
    payload: {siteid,data,token}
});

export const customerListSuccess = (customers: string): StoreAction => ({
    type: CUSTOMER_LIST_SUCCESS,
    payload: customers
});

export const customerListFailed = (error: string): StoreAction => ({
    type: CUSTOMER_LIST_FAILED,
    payload: error
});

export const storeAddress = (siteid: string, token: string): StoreAction => ({
    type: STORE_ADDRESS,
    payload: {siteid,token}
});

export const storeAddressSave = (siteid: string, data: string, token: string): StoreAction => ({
    type: STORE_ADDRESS_SAVE,
    payload: {siteid,data,token}
});

export const storeAddressSuccess = (address: string): StoreAction => ({
    type: STORE_ADDRESS_SUCCESS,
    payload: address
});

export const storeAddressFailed = (error: string): StoreAction => ({
    type: STORE_ADDRESS_FAILED,
    payload: error
});

export const storeOrderNo = (siteid: string, token: string): StoreAction => ({
    type: STORE_ORDER_NO,
    payload: {siteid,token}
});

export const storeOrderNoSave = (siteid: string, data: string, token: string): StoreAction => ({
    type: STORE_ORDER_NO_SAVE,
    payload: {siteid,data,token}
});

export const storeOrderNoSuccess = (orderno: string): StoreAction => ({
    type: STORE_ORDER_NO_SUCCESS,
    payload: orderno
});

export const storeOrderNoFailed = (error: string): StoreAction => ({
    type: STORE_ORDER_NO_FAILED,
    payload: error
});

export const storeShipping = (siteid: string, token: string): StoreAction => ({
    type: STORE_SHIPPING,
    payload: {siteid,token}
});

export const storeShippingFlatrateSave = (siteid: string, data: string, method: string, token: string): StoreAction => ({
    type: STORE_SHIPPING_FLATRATE_SAVE,
    payload: {siteid,data,method,token}
});

export const storeShippingSuccess = (shipping: string): StoreAction => ({
    type: STORE_SHIPPING_SUCCESS,
    payload: shipping
});

export const storeShippingFailed = (error: string): StoreAction => ({
    type: STORE_SHIPPING_FAILED,
    payload: error
});

export const storeShippingFree = (siteid: string, token: string): StoreAction => ({
    type: STORE_SHIPPING_FREE,
    payload: {siteid,token}
});

export const storeShippingFreeSave = (siteid: string, data: string, method: string, token: string): StoreAction => ({
    type: STORE_SHIPPING_FREE_SAVE,
    payload: {siteid,data,method,token}
});

export const storeShippingFreeSuccess = (shipping: string): StoreAction => ({
    type: STORE_SHIPPING_FREE_SUCCESS,
    payload: shipping
});

export const storeShippingFreeFailed = (error: string): StoreAction => ({
    type: STORE_SHIPPING_FREE_FAILED,
    payload: error
});

export const storePaymentBacs = (siteid: string, token: string): StoreAction => ({
    type: STORE_PAYMENT_BACS,
    payload: {siteid,token}
});

export const storePaymentBacsSave = (siteid: string, data: string, method: string, token: string): StoreAction => ({
    type: STORE_PAYMENT_BACS_SAVE,
    payload: {siteid,data,method,token}
});

export const storePaymentBacsSuccess = (bacs: string): StoreAction => ({
    type: STORE_PAYMENT_BACS_SUCCESS,
    payload: bacs
});

export const storePaymentBacsFailed = (error: string): StoreAction => ({
    type: STORE_PAYMENT_BACS_FAILED,
    payload: error
});

export const storePaymentPaypal = (siteid: string, token: string): StoreAction => ({
    type: STORE_PAYMENT_PAYPAL,
    payload: {siteid,token}
});

export const storePaymentPaypalSave = (siteid: string, data: string, token: string): StoreAction => ({
    type: STORE_PAYMENT_PAYPAL_SAVE,
    payload: {siteid,data,token}
});

export const storePaymentPaypalSuccess = (paypal: string): StoreAction => ({
    type: STORE_PAYMENT_PAYPAL_SUCCESS,
    payload: paypal
});

export const storePaymentPaypalFailed = (error: string): StoreAction => ({
    type: STORE_PAYMENT_PAYPAL_FAILED,
    payload: error
});