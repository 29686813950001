// @flow
import {
    COOKIE_BAR,
    COOKIE_BAR_SUCCESS,
    COOKIE_BAR_FAILED,
    COOKIE_BAR_SAVE
} from '../../constants/actionTypes';

type PdpaAction = { type: string, payload: {} | string };

export const cookieBar = (siteid: string, token: string): PdpaAction => ({
    type: COOKIE_BAR,
    payload: {siteid,token}
});

export const cookieBarSuccess = (cookiebar: string): StoreAction => ({
    type: COOKIE_BAR_SUCCESS,
    payload: cookiebar
});

export const cookieBarFailed = (error: string): StoreAction => ({
    type: COOKIE_BAR_FAILED,
    payload: error
});

export const cookieBarSave = (siteid: string, data: string, token: string): IntegrationsAction => ({
    type: COOKIE_BAR_SAVE,
    payload: {siteid, data, token}
});